import React, { useState, useEffect } from "react";

export default function Heading() {
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch("https://demo.tessaract.eticloud.uk/logo");
        if (response.ok) {
          const data = await response.json();
          setLogoUrl(data.logoUrl);
        } else {
          setLogoUrl(null);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
        setLogoUrl(null);
      }
    };
    fetchLogo();
  }, []);

  return (
    <>
      <h1 className="cloud-law-heading font-semibold">Cloud Law Solicitors</h1>
      <p className="mb-3 text-sm">Enter your no-obligation, confidential enquiry</p>
    </>
  );
}
