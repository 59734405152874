import React, { useState } from "react";
import Heading from "./Heading";

export default function Form() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    description: "",
    areaOfLaw: "",
    residentialAddress: "",
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstname, lastname, email, mobile, areaOfLaw } = formData;
    if (!firstname || !lastname || !email || !mobile || !areaOfLaw) {
      alert("Please fill in all required fields: First Name, Last Name, Email, and Mobile.");
      return;
    }
    console.log(formData);
    setLoading(true);

    try {
      const response = await fetch("https://demo.tessaract.eticloud.uk/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(true);

        // Reset success state
        setTimeout(() => {
          setSuccess(false);
        }, 1000);
      } else {
        alert("There was an error submitting your form. Please try again");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <img className="background-image-1" src="./images/corners/red.png" alt="" />
      <img className="background-image-2" src="./images/corners/green.png" alt="" />
      <form className="matter-form p-4 shadow-md rounded-lg border-2 flex flex-col" onSubmit={handleSubmit}>
        <div className="background-images" />
        <Heading className="mx-auto" />
        <div className="flex my-6">
          <div className="w-1/2 pr-2">
            <label className="block font-bold mb-2">
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="firstname"
              placeholder="Enter your first name"
              className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.firstname}
              onChange={handleChange}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block font-bold mb-2">
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="lastname"
              placeholder="Enter your last name"
              className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.lastname}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">
            Area of Law <span className="required">*</span>
          </label>
          <select
            name="areaOfLaw"
            className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.areaOfLaw}
            onChange={handleChange}
          >
            <option value="">Select an area of law</option>
            <option value="12107">Conveyance Sale</option>
            <option value="12108">Conveyance Purchase</option>
            <option value="12112">Remortgage</option>
            <option value="12112">Family Law</option>
            <option value="12109">Corporate Law</option>
            <option value="12110">Criminal Law</option>
            <option value="12106">Incorporation</option>
            <option value="12116">Uncontested Divorce</option>
            <option value="12117">Power of Attorney</option>
            <option value="12119">Divorce</option>
            <option value="12121">Personal Injury</option>
            <option value="12115">Probate</option>
            <option value="12114">Other</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">
            Email <span className="required">*</span>
          </label>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="pr-2 mb-4">
          <label className="block font-bold mb-2">
            Mobile <span className="required">*</span>
          </label>
          <input
            type="text"
            name="mobile"
            placeholder="Enter your mobile"
            className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.mobile}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">What can we help you with? (optional)</label>
          <textarea
            name="description"
            placeholder="Enter brief matter description"
            className="w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="5"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mt-8">
          <button
            type="submit"
            className={`w-full text-white p-2 rounded-md transition duration-300 font-bold tracking-wide ${
              success ? "bg-green-500" : loading ? "bg-gray-400" : "bg-blue-500"
            }`}
            disabled={loading}
          >
            {success ? (
              <span className="flex items-center justify-center">
                <img src="./images/check-circle.svg" alt=""></img>
              </span>
            ) : (
              <>
                {loading && (
                  <span
                    className="animate-spin inline-block w-4 h-4 border-2 rounded-full border-t-transparent border-blue-500 mr-4"
                    style={{ borderTopColor: "#23DCF9" }}
                  ></span>
                )}
                Submit
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
