import { useEffect } from "react";
import "./App.css";
import Form from "./components/Form";

function App() {
  // Load the Watson chatbot
  useEffect(() => {
    window.watsonAssistantChatOptions = {
      integrationID: "e1bc4bd7-b05c-4a54-8cff-56aff9bb0057",
      region: "eu-gb",
      serviceInstanceID: "ed58a57b-c0f7-42af-ab89-ffe5b99bd6b7",
      onLoad: async (instance) => {
        await instance.render();
      },
    };

    setTimeout(() => {
      const script = document.createElement("script");
      script.src =
        "https://web-chat.global.assistant.watson.appdomain.cloud/versions/" +
        (window.watsonAssistantChatOptions.clientVersion || "latest") +
        "/WatsonAssistantChatEntry.js";
      script.async = true;
      document.head.appendChild(script);
    }, 0);
  }, []);

  return (
    <div className="App">
      <Form />
    </div>
  );
}

export default App;
